import ArrowAngleOutlinedSVG from './ArrowAngleOutlined.svg';
import ArrowLeftOutlinedSVG from './ArrowLeftOutlined.svg';
import ArrowLendingUpGreenSVG from './ArrowLendingUpGreen.svg';
import ArrowRightOutlinedSVG from './ArrowRightOutlined.svg';
import ArrowUpOutlinedSVG from './ArrowUpOutlined.svg';
import LeftOutlined from './LeftOutlined.svg';

export {
  ArrowLendingUpGreenSVG,
  LeftOutlined,
  ArrowAngleOutlinedSVG,
  ArrowLeftOutlinedSVG,
  ArrowRightOutlinedSVG,
  ArrowUpOutlinedSVG,
};
