import ActiveNewSVG from './new/Active.svg';
import ErrorNewSVG from './new/Error.svg';
import InactiveNewSVG from './new/Inactive.svg';
import ActiveOldSVG from './old/Active.svg';
import DotOldSVG from './old/Dot.svg';
import InactiveOldSVG from './old/Inactive.svg';

export {
  ActiveNewSVG,
  InactiveNewSVG,
  ErrorNewSVG,
  ActiveOldSVG,
  DotOldSVG,
  InactiveOldSVG,
};
