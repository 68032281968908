import { useLocale, useTranslations } from 'next-intl';
import { FC, memo, useCallback, useMemo } from 'react';
import cls from './oldOrNewPrice.module.css';
import { HStack } from '../../Stack';
import { Text } from '../../Text';
import { classNames } from '@/shared/lib/classNames/classNames';
import { selectPeriodPackages } from '@/shared/lib/helpers/selectPeriodPackages/selectPeriodPackages';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';

interface IOldOrNewPriceProps {
  className?: string;
  oldPrice?: string | null;
  newPrice: string;
  period: number;
  size: 'l' | 'm' | 's';
}

export const OldOrNewPrice: FC<IOldOrNewPriceProps> = memo(props => {
  const { className, newPrice, oldPrice, period, size } = props;
  const t = useTranslations();
  const { isMobile } = useDetectDevice();
  const lang = useLocale();

  const currentPeriod = useMemo(
    () => selectPeriodPackages(period, t),
    [period, t],
  );

  const currentPrice = useCallback(
    (price: string) => Number.parseInt(price || '0'),
    [],
  );

  const preparePrice = useCallback(
    (price: string) =>
      lang === 'ru' ? `${currentPrice(price)}$` : `$${currentPrice(price)}`,
    [lang, currentPrice],
  );

  const sizeNewPrice = useMemo(() => {
    if (size === 'm') return 'numbers-m';
    if (size === 's') return 'numbers-s';
    return 'numbers-l';
  }, [size]);

  const sizeOldPrice = useMemo(
    () => (isMobile ? 'numbers-x-s' : 'numbers-s'),
    [isMobile],
  );

  const sizePeriod = useMemo(() => {
    if (size === 'm') return 'body-m';
    if (size === 's') return 'body-s';
    return 'body-l';
  }, [size]);

  return (
    <HStack
      align='baseline'
      className={classNames(cls['old-or-new-price'], {}, [className])}
    >
      {!!oldPrice && (
        <HStack gap='0' className={cls.period}>
          <Text as='h3' variant={sizeOldPrice}>
            {preparePrice(oldPrice)}
          </Text>
        </HStack>
      )}
      <HStack align='start'>
        <Text as='h1' variant={sizeNewPrice}>
          {preparePrice(newPrice)}
        </Text>
      </HStack>
      <Text variant={sizePeriod} className={cls.period}>
        {currentPeriod}
      </Text>
    </HStack>
  );
});
