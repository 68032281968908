import { FC, ReactNode, StyleHTMLAttributes, useCallback } from 'react';
import cls from './Page.module.css';
import { PAGE_ID } from '@/shared/const/pageId';
import { classNames, Mods } from '@/shared/lib/classNames/classNames';
import { useInitialEffect } from '@/shared/lib/hooks/useInitialEffect/useInitialEffect';
import { useAppPathname } from '@/shared/lib/router/navigation';
import { pathWithHeader } from '@/shared/lib/router/pathWithHeader/pathWithHeader';
import { TestProps } from '@/shared/types/tests';
import { Align, Justify } from '@/shared/types/ui';
import { VStack } from '@/shared/ui/Stack';

interface IPageProps extends TestProps, StyleHTMLAttributes<HTMLBodyElement> {
  className?: string;
  children: ReactNode;
  justify?: Justify;
  align?: Align;
  /**
   * max-width: 100%;
   */
  maxWidth?: boolean;
  /**
   *  position: relative;
   */
  relative?: boolean;
}

export const Page: FC<IPageProps> = props => {
  const {
    className,
    children,
    maxWidth = false,
    relative = false,
    justify = 'center',
    align = 'top',
    ...otherProps
  } = props;

  const pathname = useAppPathname();

  const scrollUp = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  useInitialEffect(scrollUp);

  const withHeader = pathWithHeader(pathname);

  const classesContent = classNames(cls.content, {}, [
    cls[justify],
    cls[align],
    className,
  ]);

  const mods: Mods = {
    [cls['with-header']]: withHeader,
    [cls['max-width']]: maxWidth,
    [cls.relative]: relative,
  };

  const innerMods: Mods = {
    [cls['max-width']]: maxWidth,
  };

  return (
    <main
      id={PAGE_ID}
      data-testid={otherProps['data-testid'] || 'Page'}
      className={classNames(cls.page, mods, [])}
      {...otherProps}
    >
      <VStack max className={classNames(classesContent, innerMods, [])}>
        {children}
      </VStack>
    </main>
  );
};
